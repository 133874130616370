<template>
  <vs-card id="empty-table-wrapper">
    <div class="container">
      <img
        class="empty-bot"
        :src="image"
        alt="Bot image"
      />
      <br />
      <h4>{{ text }}</h4>

      <br />
      <vs-button v-if="buttonAction && buttonText" @click="buttonAction">
        {{ buttonText }}
      </vs-button>
    </div>
  </vs-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'EmptyTable',
  props: {
    text: {
      type: String,
      required: true
    },
    imageSrc: String,
    buttonText: String,
    buttonAction: Function
  },

  computed: {
    ...mapState(['lang', 'languageSelected']),
    image() {
      let img;
      if (this.imageSrc) {
        img = require(this.imageSrc);
      } else {
        img = require('@/assets/images/bot-image.svg');
      }
      return img;
    }
  }
}
</script>

<style lang="scss" scoped>
#empty-table-wrapper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .empty-bot {
    max-height: 200px;
  }
}
</style>
