import { render, staticRenderFns } from "./EmptyTable.vue?vue&type=template&id=0d63ed3c&scoped=true&"
import script from "./EmptyTable.vue?vue&type=script&lang=js&"
export * from "./EmptyTable.vue?vue&type=script&lang=js&"
import style0 from "./EmptyTable.vue?vue&type=style&index=0&id=0d63ed3c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d63ed3c",
  null
  
)

export default component.exports